<template>
  <h-form class="activate-card-form" ref="activate-card" v-model="valid" @submit.prevent="activateCard">
    <div class="form-row">
      <h-text-field autocomplete="shipping given-name" v-model="firstName" :disabled="firstNameIsPrefill" :rules="firstNameRules" :label="$t('components.activate_card_step_form.first_name_label')" maxlength="50" />

      <h-text-field
        v-maska="{ mask: '####' }"
        v-model="birthYear"
        :label="$t('components.activate_card_step_form.year_of_birth_label')"
        pattern="[0-9][0-9][0-9][0-9]"
        :rules="birthYearRules"
        required
        maxlength="4"
        class="col-right"
        :disabled="yearOfBirthIsPrefill"
      />
      <h-select v-model="gender" :disabled="genderIsPrefill" class="col-right mb-5" :label="$t('components.activate_card_step_form.gender_label')" :items="genderChoices" item-text="name" item-value="abbr" :rules="genderRules" hide-details dense />
    </div>

    <div class="form-row">
      <h-select v-model="currentCountry" :rules="countryRules" :disabled="countryIsPrefill" :label="$t('components.activate_card_step_form.country_label')" :items="countryList" item-text="text" />
      <h-text-field
        autocomplete="shipping postal-code"
        v-show="postCodeRequired"
        v-maska="{ mask: 'A#A #A#' }"
        :disabled="postalCodeIsPrefill || !postCodeRequired"
        v-model="postalCode"
        clearable
        :rules="postalCodeRules"
        :label="$t('components.activate_card_step_form.postal_code_label')"
        class="col-right"
        maxlength="7"
      />
    </div>

    <h-checkbox
      class="checkbox-label"
      v-if="includeAllowCommunication"
      :disabled="working"
      top-align
      :label="$t('components.activate_card_step_form.allow_communication', { partner: instantCardOffer.partner_label[currentLocale] })"
      v-model="allowCommunication"
    />

    <h-checkbox v-model="AgreementConsent" :rules="consentRules" style="margin-top: var(--gap)">
      <template v-slot:label>
        <span class="checkbox-label">
          {{ $t('components.activate_card_step_form.consent_label') }}
          <a @click.stop target="_blank" :href="currentLocale === 'fr' ? $urlProvider.HelloCardholderInstantCardAgreementFr : $urlProvider.HelloCardholderInstantCardAgreementEn">{{ $t('components.activate_card_step_form.cardholder_agreement') }}</a>
          {{ $t('components.activate_card_step_form.and') }}
          <a @click.stop target="_blank" :href="currentLocale === 'fr' ? $urlProvider.HelloElectronicCommunicationConsentAgreementFr : $urlProvider.HelloElectronicCommunicationConsentAgreementEn">{{
            $t('components.activate_card_step_form.electronic_communications_agreement')
          }}</a>
        </span>
      </template>
    </h-checkbox>

    <activate-card-success-modal v-model="successModalIsOpen" :pin-error="pinError" :pin="pin" />
    <activate-card-error-modal v-model="errorModalIsOpen" />
  </h-form>
</template>
<script>
import { validateNeedChecked, validatePostalCodeFormatInsensitive, validatePostalCodeInsensitive, validateMaxLength, validateRequired, validateYearOfBirth } from '@/composables/validations';
import { recaptcha } from '@/mixins';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import ActivateCardSuccessModal from '@/components/instant-card-activation/ActivateCardSuccessModal';
import ActivateCardErrorModal from '@/components/instant-card-activation/ActivateCardErrorModal';

export default {
  name: 'activate-card-step-form',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);

    return {
      executeForm,
      working,
      validatePostalCodeFormatInsensitive,
      validatePostalCodeInsensitive,
      validateMaxLength,
      validateRequired,
      validateYearOfBirth,
      validateNeedChecked,
    };
  },
  components: { ActivateCardSuccessModal, ActivateCardErrorModal },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      firstNameRules: [this.validateRequired('first_name'), this.validateMaxLength(50)],
      birthYearRules: [this.validateRequired('birth_year'), this.validateYearOfBirth()],
      countryRules: [this.validateRequired()],
      consentRules: [this.validateNeedChecked()],
      genderRules: [this.validateRequired('gender')],
      countryIsPrefill: false,
      postalCodeIsPrefill: false,
      yearOfBirthIsPrefill: false,
      firstNameIsPrefill: false,
      genderIsPrefill: false,

      datePickerMenu: false,

      firstName: '',
      birthYear: null,
      gender: '',
      currentCountry: 'CA',
      postalCode: undefined,
      AgreementConsent: false,
      successModalIsOpen: false,
      pin: '',

      errorModalIsOpen: false,
      pinError: false,
      allowCommunication: true,
    };
  },
  mounted() {
    if (this.accountFirstName) {
      this.firstNameIsPrefill = true;
      this.firstName = this.accountFirstName;
    }
    if (this.accountYearOfBirth) {
      this.yearOfBirthIsPrefill = true;
      this.birthYear = this.accountYearOfBirth;
    }
    if (this.accountCountry) {
      this.countryIsPrefill = true;
      this.currentCountry = this.accountCountry;
    }
    if (this.accountPostalCode) {
      this.postalCodeIsPrefill = true;
      this.postalCode = this.accountPostalCode;
    }
    if (this.accountGender) {
      this.genderIsPrefill = true;
      this.gender = this.accountGender;
    }
  },
  watch: {
    datePickerMenu(val) {
      // This is to ensure that the year picker is showing first when the menu open
      val && this.$nextTick(() => (this.$refs.birthDatePicker.activePicker = 'YEAR'));
    },
    currentCountry(val, oldVal) {
      if (val !== oldVal) {
        this.postalCode = undefined;
      }
    },
    accountFirstName() {
      if (this.accountFirstName) {
        this.firstNameIsPrefill = true;
        this.firstName = this.accountFirstName;
      }
    },
    accountYearOfBirth() {
      if (this.accountYearOfBirth) {
        this.yearOfBirthIsPrefill = true;
        this.birthYear = this.accountYearOfBirth;
      }
    },
    accountGender() {
      if (this.accountGender) {
        this.genderIsPrefill = true;
        this.gender = this.accountGender;
      }
    },
    accountCountry() {
      if (this.accountCountry) {
        this.countryIsPrefill = true;
        this.currentCountry = this.accountCountry;
      }
    },
    accountPostalCode() {
      if (this.accountPostalCode) {
        this.postalCodeIsPrefill = true;
        this.postalCode = this.accountPostalCode;
      }
    },
    successModalIsOpen() {
      if (!this.successModalIsOpen) {
        this.$emit('next');
      }
    },
  },
  computed: {
    ...mapGetters('instantCard', ['countryList', 'instantCardPartnerId', 'instantCardOffer', 'hasInstantCardOffer']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('account', ['accountCountry', 'accountPostalCode', 'accountFirstName', 'accountYearOfBirth', 'accountGender', 'accountActiveMemberships']),
    ...mapGetters('space', ['currentPartnerId']),
    includeAllowCommunication() {
      return (!this.membership || !this.membership.allow_communication) && this.hasInstantCardOffer;
    },
    membership() {
      return _find(this.accountActiveMemberships, (activeMembership) => activeMembership.partner_id === this.instantCardPartnerId);
    },
    postalCodeRules() {
      if (this.postCodeRequired) {
        return [this.validateRequired('postal_code'), this.validatePostalCodeFormatInsensitive(), this.validatePostalCodeInsensitive()];
      }
      return [];
    },
    postCodeRequired() {
      if (!this.currentCountry) {
        return false;
      }
      const currentCountryFromList = _find(this.countryList, (c) => c.value === this.currentCountry);
      return currentCountryFromList && currentCountryFromList.postal_code_required;
    },
    genderChoices() {
      const choices = ['female', 'male', 'non_binary'];
      return _map(choices, (choice) => {
        return { name: this.$t(`components.shared.genders.${choice}`), abbr: choice };
      });
    },
  },
  methods: {
    async activateCard(publisherId) {
      if (this.$refs['activate-card'].validate()) {
        const params = {
          recaptcha_token: await this.recaptcha('activateInstantCard'),
          first_name: this.firstName,
          country: this.currentCountry,
          postal_code: this.postalCode,
          year_of_birth: this.birthYear,
          gender: this.gender,
        };

        if (this.includeAllowCommunication) {
          params.allow_communication = this.allowCommunication;
        }

        if (publisherId) {
          params.publisher_id = publisherId;
        }

        if (this.currentPartnerId) {
          params.partner_id = this.currentPartnerId;
        }

        this.executeForm(
          {
            action: 'instantCard/activateInstantCard',
            success: (pin) => {
              this.successModalIsOpen = true;
              if (!pin) {
                this.pinError = true;
              } else {
                this.pin = pin;
              }
            },
            afterError: (err) => {
              this.errorModalIsOpen = true;
            },
          },
          params,
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activate-card-form {
  max-width: 560px;
  margin: 0 auto;
  text-align: center;
}

.form-row {
  @media ($sm-min) {
    display: flex;

    .col-right {
      width: 215px;
      margin-left: var(--gap);
    }
  }
}

.v-menu__content {
  box-shadow: var(--card-shadow);
}
.checkbox-label ::v-deep .v-label {
  color: var(--color-text-primary);
}

::v-deep .v-input--checkbox .v-input__slot {
  align-items: flex-start;

  .checkbox-label {
    color: var(--color-text-primary);
  }

  a {
    font-weight: normal;
    color: var(--color-text-primary);
  }
}
</style>
